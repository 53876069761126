import { AgendaDomContentLoad } from "@digitaluc/uc-kitdigital/src/js/agenda";
import { Accordion } from "@digitaluc/uc-kitdigital/src/js/components/accordion";
import { Dropdown } from "@digitaluc/uc-kitdigital/src/js/components/dropdown";
import { Footer } from "@digitaluc/uc-kitdigital/src/js/globals/Footer";
import { FooterEn } from "@digitaluc/uc-kitdigital/src/js/globals/FooterEn";
import { MobileNavbar } from "@digitaluc/uc-kitdigital/src/js/components/mobileNavbar";
import { Modal } from "@digitaluc/uc-kitdigital/src/js/components/modal";
import { Nav } from "@digitaluc/uc-kitdigital/src/js/components/nav";
import { Navbar } from "@digitaluc/uc-kitdigital/src/js/components/navbar";
import { Tab } from "@digitaluc/uc-kitdigital/src/js/components/tab";
import { Tooltip } from "@digitaluc/uc-kitdigital/src/js/components/tooltip";
import { Topbar } from "@digitaluc/uc-kitdigital/src/js/globals/Topbar";
import { TopbarUCChile } from "@digitaluc/uc-kitdigital/src/js/globals/TopbarUCChile";
import { TopbarEn } from "@digitaluc/uc-kitdigital/src/js/globals/TopbarEn";
import { UcCarousel } from "@digitaluc/uc-kitdigital/src/js/components/carousels";
import BackgroundVideo from "@digitaluc/uc-kitdigital/src/js/components/background-video";

document.addEventListener("turbolinks:load", async () => {
    new AgendaDomContentLoad();
    new Accordion();
    new Dropdown();
    new Footer();
    new FooterEn();
    new MobileNavbar();
    new Modal();
    new Nav();
    new Navbar();
    new Tab();
    new Tooltip();
    new Topbar();
    new TopbarEn();
    new TopbarUCChile();
    new UcCarousel();
    new BackgroundVideo();
  });